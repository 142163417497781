const breakpoints = ({ type }: { type: 'min' | 'max' }) => ({
  xs: `@media (${type}-width: 0px)`,
  sm: `@media (${type}-width: 600px)`,
  md: `@media (${type}-width: 900px)`,
  lg: `@media (${type}-width: 1200px)`,
  xl: `@media (${type}-width: 1536px)`,
});

const theme = {
  grid: {
    xcontainer: '160rem',
    container: '130rem',
    gutter: '3.2rem',
  },
  border: {
    radius: '0.8rem',
  },
  logoSize: {
    min: { width: 32, height: 32 },
    default: { width: 121, height: 32 },
    auth: { width: 121, height: 32 },
  },
  font: {
    family: '"Raleway", Arial, sans-serif',
    normal: 500,
    bold: 700,
    xbold: 800,
    xxbold: 900,
    sizes: {
      xsmall: '1.2rem',
      small: '1.4rem',
      medium: '1.6rem',
      large: '1.8rem',
      xlarge: '2.0rem',
      xxlarge: '2.8rem',
    },
  },
  colors: {
    info: '#0094E8',
    primary: '#0094E8',
    secondary: '#8DA0CA',
    success: '#29CD7E',
    danger: '#DD375F',
    warning: '#FF9900',
    white: '#fff',
    black: '#364463',
    borders: '#D7E2EA',
    lightGray: '#F5F8FA',
    mediumGray: '#6F7FA4',
    company: '#003470',
    defaultBackgroundColor: '#F5F8FA',
    defaultBackgroundCardDash: 'linear-gradient( 45deg,#003470,#000000 60% )',
  },
  spacings: {
    xxsmall: '0.8rem',
    xsmall: '1.6rem',
    small: '2.4rem',
    medium: '3.2rem',
    large: '4.0rem',
    xlarge: '4.8rem',
    xxlarge: '5.6rem',
  },
  layers: {
    base: 10,
    menu: 20,
    overlay: 30,
    modal: 40,
    alwaysOnTop: 50,
  },
  transition: {
    default: '0.5s ease-in-out',
    fast: '0.3s ease-in-out',
  },
  breakpoints: {
    up: breakpoints({ type: 'min' }),
    down: breakpoints({ type: 'max' }),
  },
};

export default theme;

export type ColorMode = keyof typeof theme.colors;
export type SizeMode = keyof typeof theme.font.sizes;
