import { API_URL, API_URL_V4 } from '@/common/consts/api';
import axios, { AxiosRequestTransformer, AxiosResponse } from 'axios';
import { getSession } from 'next-auth/react';

export const makeApiUrl = (path = ''): string => {
  return `${API_URL}${path}`;
};

export const makeApiUrlV4 = (path = ''): string => {
  return `${API_URL_V4}${path}`;
};

function cleanJSON(json: Record<string, any>) {
  for (const key in json) {
    if (json[key] === '') {
      json[key] = null;
    } else if (typeof json[key] === 'object') json[key] = cleanJSON(json[key]);
  }
  return json;
}

const axiosInstance = axios.create({
  baseURL: `${makeApiUrl('/api')}`,
  transformRequest: [
    (data: any, headers: any) => {
      if (
        headers?.put['Content-Type'] !== 'application/json' ||
        headers?.post['Content-Type'] !== 'application/json'
      )
        return data;
      return cleanJSON(data);
    },
    ...(axios.defaults.transformRequest as AxiosRequestTransformer[]),
  ],
});
const axiosInstanceV4 = axios.create({
  baseURL: `${makeApiUrlV4('/api-v4')}`,
  transformRequest: [
    (data: any, headers: any) => {
      if (
        headers?.put['Content-Type'] !== 'application/json' ||
        headers?.post['Content-Type'] !== 'application/json'
      )
        return data;
      return cleanJSON(data);
    },
    ...(axios.defaults.transformRequest as AxiosRequestTransformer[]),
  ],
});

const axiosInstanceAuthorized = axios.create({
  baseURL: `${makeApiUrl('/api')}`,
  transformRequest: [
    (data: any) => {
      return cleanJSON(data);
    },
    ...(axios.defaults.transformRequest as AxiosRequestTransformer[]),
  ],
});

const axiosInstanceAuthorizedV4 = axios.create({
  baseURL: `${makeApiUrlV4('/api-v4')}`,
  transformRequest: [
    (data: any) => {
      return cleanJSON(data);
    },
    ...(axios.defaults.transformRequest as AxiosRequestTransformer[]),
  ],
});

const interceptorsResponse = [
  (response: AxiosResponse) => response,
  (e: any): Promise<AxiosResponse> => {
    return Promise.reject(e);
  },
];

axiosInstance.interceptors.response.use(...interceptorsResponse);
axiosInstanceAuthorized.interceptors.response.use(...interceptorsResponse);

const getPublicApiInstance = () => axiosInstance;
const getPublicApiInstanceV4 = () => axiosInstanceV4;

axiosInstanceAuthorized.interceptors.request.use(async request => {
  let session = await getSession();

  if (session) {
    request!.headers!.Authorization = `Bearer ${session.accessToken}`;
  }
  return request;
});

axiosInstanceAuthorizedV4.interceptors.request.use(async request => {
  let session = await getSession();

  if (session) {
    request!.headers!.Authorization = `Bearer ${session.accessToken}`;
    request!.headers!.intermediateId = session.user.currentIntermediate
      ?.id as string;
  }

  return request;
});

const getAuthorizedApiInstance = () => axiosInstanceAuthorized;
const getAuthorizedApiInstanceV4 = () => axiosInstanceAuthorizedV4;

export {
  getPublicApiInstance,
  getPublicApiInstanceV4,
  getAuthorizedApiInstance,
  getAuthorizedApiInstanceV4,
};
