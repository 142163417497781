export const AccessDeniedErrorName = 'AccessDeniedError';
export const UnexpectedErrorName = 'UnexpectedError';
export const EmailInUseErrorName = 'EmailInUseError';
export const InvalidCredentialsErrorName = 'InvalidCredentialsError';
export const PendingEmailConfirmationErrorName =
  'PendingEmailConfirmationError';
export const AwaitingEmailConfirmationErrorName =
  'AwaitingEmailConfirmationError';
export const EmailIsConfirmedErrorName = 'EmailIsConfirmedError';
export const DocumentInUseErrorName = 'DocumentInUseError';
export const TakerAlreadyLinkedName = 'TakerAlreadyLinkedError';
export const CannotDisableLastGroupAccountName =
  'CannotDisableLastGroupAccountError';
export const PendingIssuePolicyErrorName = 'PendingIssuePolicyError';
export const InvalidProcessStatusErrorName = 'InvalidProcessStatusError';
