import differenceInMilliseconds from 'date-fns/differenceInMilliseconds';
import { signOut, useSession } from 'next-auth/react';
import { useEffect } from 'react';

type Props = {
  setRefetchIntervalSeconds: (timeRemaining: number) => void;
};

export const RefreshTokenHandler = ({ setRefetchIntervalSeconds }: Props) => {
  const { data: session } = useSession();
  const msTimePreventRefresh = 5 * 60 * 1000; // 5 minute

  useEffect(() => {
    if (!!session) {
      if (session.error === 'RefreshAccessTokenError') {
        // Obtém o domínio atual dinamicamente
        const protocol =
          typeof window !== 'undefined' && window.location.protocol;
        const host = typeof window !== 'undefined' && window.location.host;
        const baseUrl = `${protocol}//${host}`;
        signOut({
          // callbackUrl: `${baseUrl}/auth/login`,
        });
        return;
      }

      const msToPreventExpiredToken = differenceInMilliseconds(
        new Date(session.accessTokenExpiry * 1000 - msTimePreventRefresh),
        new Date()
      );

      setRefetchIntervalSeconds(
        msToPreventExpiredToken > 0 ? msToPreventExpiredToken / 1000 : 0
      );
    }
  }, [JSON.stringify({ ...session, expires: null })]);

  return null;
};
