import { createGlobalStyle, css } from 'styled-components';

const GlobalStyle = createGlobalStyle`
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-variant-numeric: lining-nums !important;
}

${({ theme }) => css`
  html {
    font-size: 62.5%;
  }

  body {
    background-color: ${theme.colors.defaultBackgroundColor};
    font-family: ${theme.font.family};
    font-size: ${theme.font.sizes.medium};
    color: ${theme.colors.black};
  }
`}
`;

export default GlobalStyle;
