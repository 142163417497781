export const dateToBrFormat = ({
  date,
  ignoreTimezone,
  withTime,
}: {
  date: Date | null | undefined;
  ignoreTimezone?: boolean;
  withTime?: boolean;
}): string | null => {
  if (!date) return null;
  return date.toLocaleDateString('pt-Br', {
    day: 'numeric',
    month: 'numeric',
    year: 'numeric',
    timeZone: ignoreTimezone ? 'UTC' : undefined,
    hour: withTime ? 'numeric' : undefined,
    minute: withTime ? 'numeric' : undefined,
  });
};

export const dateToTimeBrFormat = ({
  date,
  ignoreTimezone,
}: {
  date: Date;
  ignoreTimezone?: boolean;
}): string | null => {
  if (!date) return null;
  return date.toLocaleTimeString('pt-Br', {
    hour: 'numeric',
    minute: 'numeric',
    timeZone: ignoreTimezone ? 'UTC' : undefined,
  });
};

export const dateBrToDate = ({
  dateBrFormat,
  hours,
  minutes,
}: {
  dateBrFormat: string;
  hours?: number;
  minutes?: number;
}): Date | null | undefined => {
  if (!dateBrFormat) return null;
  const [day, month, year] = dateBrFormat.toString().split('/');
  const date = new Date(Number(year), Number(month) - 1, Number(day));
  if (hours) date.setHours(hours);
  if (minutes) date.setMinutes(minutes);
  return date;
};

export const daysBetween = ({
  startDate,
  endDate,
}: {
  startDate: Date;
  endDate: Date;
}): number | null | undefined => {
  if (!startDate || !endDate) return null;
  // The number of milliseconds in all UTC days (no DST)
  const oneDay = 1000 * 60 * 60 * 24;

  // A day in UTC always lasts 24 hours (unlike in other time formats)
  const start = Date.UTC(
    endDate.getFullYear(),
    endDate.getMonth(),
    endDate.getDate()
  );
  const end = Date.UTC(
    startDate.getFullYear(),
    startDate.getMonth(),
    startDate.getDate()
  );

  // so it's safe to divide by 24 hours
  return (start - end) / oneDay;
};

export const compareDates = ({
  firstDate,
  secondDate,
  compareType,
}: {
  firstDate: Date;
  secondDate: Date;
  compareType: 'gt' | 'lt' | 'gte' | 'lte' | 'eq' | 'ne';
}) => {
  firstDate.setHours(0, 0, 0, 0);
  secondDate.setHours(0, 0, 0, 0);

  // gt: teste se um campo é maior que um valor constante
  if (compareType === 'gt') {
    return firstDate > secondDate;
  }
  // gte: teste se um campo é superior ou igual a um valor constante
  if (compareType === 'gte') {
    return firstDate >= secondDate;
  }
  // lt: teste se um campo é menor que um valor constante
  if (compareType === 'lt') {
    return firstDate < secondDate;
  }
  // lte: teste se um campo é inferior ou igual a um valor constante
  if (compareType === 'lte') {
    return firstDate <= secondDate;
  }
  // eq: teste se um campo é igual a um valor constante
  if (compareType === 'eq') {
    return firstDate! === secondDate;
  }
  // ne: teste se um campo difere de um valor constante
  if (compareType === 'ne') {
    return firstDate! !== secondDate;
  }
  return true;
};

export const dateStringOnlyToDate = ({
  dateString,
}: {
  dateString: string;
}): Date | undefined => {
  if (!dateString) return;

  const [year, month, day] = dateString.substring(0, 10).split('-');
  return new Date(Number(year), Number(month) - 1, Number(day), 0, 0, 0);
};
