export const PAGE_AUTH_SIGNUP = '/auth/signup';
export const PAGE_AUTH_LOGIN = '/auth/login';
export const PAGE_AUTH_SIGNUP_SUCCESSFUL = '/auth/signup-successful';
export const PAGE_AUTH_FORGOT_PASSWORD = '/auth/forgot-password';
export const PAGE_AUTH_FORGOT_PASSWORD_SUCCESSFUL =
  '/auth/forgot-password-successful';
export const PAGE_AUTH_RESET_PASSWORD = '/auth/reset-password';
export const PAGE_AUTH_RESET_PASSWORD_SUCCESSFUL =
  '/auth/reset-password-successful';
export const PAGE_BROKER_HOME = '/broker';
export const PAGE_BROKER_REQUEST_TAKER = '/broker/request/taker';
export const PAGE_BROKER_REQUEST = '/broker/request';
export const PAGE_BROKER_ACCESS_DENIED = '/broker/access-denied';
export const PAGE_BROKER_REQUEST_ID_QUOTATION = ({
  requestId,
}: {
  requestId: string;
}) => `/broker/request/${requestId}/quotation`;
export const PAGE_BROKER_POLICY = '/broker/policy';
export const PAGE_BROKER_POLICY_ID = ({ policyId }: { policyId: string }) =>
  `/broker/policy/${policyId}`;
export const PAGE_BROKER_COMMISSION = '/broker/commission';
export const PAGE_BROKER_TAKER = '/broker/taker';
export const PAGE_BROKER_TAKER_CREATE = '/broker/taker/create';
export const PAGE_BROKER_OPERATOR = '/broker/operator';
export const PAGE_BROKER_DETAIL = '/broker/detail';
export const PAGE_BROKER_GROUP = '/broker/group';
