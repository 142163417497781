// useHistory.ts
import { useEffect, useState } from 'react';

import { useRouter } from 'next/router';

// https://github.com/vercel/next.js/discussions/16337
export function useHistory() {
  const router = useRouter();
  const [history, setHistory] = useState<string[]>([]);

  useEffect(() => {
    const handleRouteChange = (url: any, { shallow }: any) => {
      if (!shallow) {
        setHistory(prevState => [...prevState, url]);
      }
    };

    router.beforePopState(() => {
      setHistory(prevState => prevState.slice(0, -2));
      return true;
    });

    router.events.on('routeChangeStart', handleRouteChange);

    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
    };
  });

  return { history, canGoBack: () => history.length > 1 };
}
