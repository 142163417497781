import { WS_URL } from '@/common/consts/api';
import { useRouter } from 'next/router';
import React, { createContext, useEffect, useState } from 'react';
import { PAGE_BROKER_HOME } from '@/common/consts/pages';
import { LoginCase } from '@/modules/auth/types/login-case';
import { LoadProfileCase } from '@/modules/auth/types/load-profile-case';
import { io, Socket } from 'socket.io-client';
import { ListBrokerCase } from '@/modules/broker/types/list-broker-case';
import { signIn, signOut, useSession } from 'next-auth/react';
import { LoadingFull } from '@/common/components/loading-full';

type AuthContextProps = {
  isAuthenticated: boolean;
  login: (params: LoginCase.Params) => Promise<void>;
  profile: LoadProfileCase.Result | undefined;
  currentIntermediate: ListBrokerCase.Model | undefined;
  onOpenLogout: () => void;
  logout: () => void;
  socket: Socket | null;
  intermediates: ListBrokerCase.Result;
};

export const AuthContext = createContext({} as AuthContextProps);

type Props = {
  children: React.ReactElement;
  intermediates: ListBrokerCase.Result;
};

export const AuthProvider = ({ children, intermediates }: Props) => {
  const session = useSession();
  const [socket, setSocket] = useState<Socket | null>(null);
  const [isOpenLogout, setIsOpenLogout] = useState(false);
  const [isFirstRender, setIsFirstRender] = useState(true);
  const profile = session.data?.user?.profile;
  const isAuthenticated = !!profile;
  const router = useRouter();

  useEffect(() => {
    if (session.status === 'authenticated') {
      const socketIo = io(WS_URL!, {
        transports: ['websocket'],
        reconnection: true,
        rejectUnauthorized: false,
        autoConnect: false,
        path: '/api-v4/socket.io',
        auth: cb => {
          cb({
            Authorization: `Bearer ${session.data?.accessToken}`,
            intermediateId: session.data?.user.currentIntermediate?.id,
          });
        },
      });

      socketIo.on('disconnect', async err => {
        setSocket(null);
        await session.update();
      });

      setSocket(socketIo);

      function cleanup() {
        socketIo.disconnect();
      }
      return cleanup;
      // should only run once and not on every re-render,
      // so pass an empty array
    }
  }, [session.status, session.data?.accessToken]);

  useEffect(() => {
    setIsFirstRender(false);
  }, []);

  const login = async ({ email, password, domain }: LoginCase.Params) => {
    const result = await signIn('credentials', {
      email,
      password,
      domain: window.location.hostname,
      redirect: false,
    });
    if (result?.error) {
      throw new Error(result.error);
    } else {
      if (router.query?.r) {
        await router.replace(router.query.r as string);
      } else {
        await router.replace(PAGE_BROKER_HOME);
      }
    }
  };

  const logout = () => {
    const protocol = typeof window !== 'undefined' && window.location.protocol;
    const host = typeof window !== 'undefined' && window.location.host;
    const baseUrl = `${protocol}//${host}`;
    signOut({
      // callbackUrl: `${baseUrl}/auth/login`,
    });
  };

  const onUpdateCurrentIntermediate = ({
    intermediate,
  }: {
    intermediate: ListBrokerCase.Result[0];
  }) => {
    session.update({
      eventName: 'selectIntermediate',
      eventData: intermediate,
    });
  };

  if (isFirstRender || session.status === 'loading') return <LoadingFull />;

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        login,
        logout,
        profile: session.data?.user.profile,
        currentIntermediate: session.data?.user?.currentIntermediate,
        onOpenLogout: () => {
          setIsOpenLogout(true);
        },
        socket,
        intermediates,
      }}>
      {children}
    </AuthContext.Provider>
  );
};
