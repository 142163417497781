import { getAuthorizedApiInstanceV4 } from '@/common/config/axios-instance';
import { UnexpectedError } from '@/common/errors';
import { handleAxiosError } from '@/common/helpers/axios-error';
import { LoadProfileCase } from '@/modules/auth/types/load-profile-case';

export const httpLoadProfile =
  async ({}: LoadProfileCase.Params): Promise<LoadProfileCase.Result> => {
    try {
      return (await getAuthorizedApiInstanceV4().get('/auth/profile')).data;
    } catch (err) {
      const { status } = handleAxiosError(err);
      switch (status) {
        default:
          throw new UnexpectedError();
      }
    }
  };
