export const API_URL = process.env.NEXT_PUBLIC_API_URL;
export const API_URL_V4 = process.env.NEXT_PUBLIC_API_URL_V4;
export const WS_URL = process.env.NEXT_PUBLIC_WS_URL;

export const ACCESS_TOKEN_NAME = 'accessToken';
export const REFRESH_TOKEN_NAME = 'refreshToken';
export const CURRENT_INTERMEDIATE_ID_NAME = 'currentIntermediateId';

export const DEFAULT_EXPIRE_REFRESH_TOKEN = 60 * 60 * 12;
export const DEFAULT_EXPIRE_ACCESS_TOKEN_TOKEN = DEFAULT_EXPIRE_REFRESH_TOKEN;
