import {
  compareDates,
  dateBrToDate,
  dateStringOnlyToDate,
} from '@/common/helpers/date';
import { numberToMoneyBr } from '@/common/helpers/money';
import * as Yup from 'yup';
import { ptForm } from 'yup-locale-pt';
import { useValidationsBR } from 'validations-br';
import { useValidatorCnj } from '@/common/helpers/process-cnj-validator';

Yup.setLocale(ptForm);

Yup.addMethod(Yup.string, 'isValidCnpj', function (errorMessage) {
  return this.test('test-cnpj', errorMessage, function (value) {
    const { path, createError } = this;

    if (!value) return true;

    return (
      useValidationsBR('cnpj', value) ||
      createError({ path, message: errorMessage || 'Informe um CNPJ válido' })
    );
  });
});

Yup.addMethod(Yup.string, 'isValidCpf', function (errorMessage) {
  return this.test('test-cpf', errorMessage, function (value) {
    const { path, createError } = this;

    if (!value) return true;

    return (
      useValidationsBR('cpf', value) ||
      createError({ path, message: errorMessage || 'Informe um CPF válido' })
    );
  });
});

Yup.addMethod(Yup.string, 'isValidCnj', function (errorMessage) {
  return this.test('test-cnj', errorMessage, function (value) {
    const { parent, path, createError } = this;
    if (!value) return true;
    if (!parent.isCNJProcedureNumber) return true;

    return (
      useValidatorCnj(value) ||
      createError({
        path,
        message:
          errorMessage ||
          'Número do processo não atende a especificação do CNJ',
      })
    );
  });
});

Yup.addMethod(Yup.string, 'isValidDocument', function (errorMessage) {
  return this.test('test-document', errorMessage, function (value) {
    const { path, createError } = this;

    if (!value) return true;

    return (
      useValidationsBR('cnpj', value) ||
      useValidationsBR('cpf', value) ||
      createError({
        path,
        message: errorMessage || 'Informe um documento válido',
      })
    );
  });
});

Yup.addMethod(Yup.string, 'maxValueLaborCourt', function (errorMessage) {
  return this.test('test-maxValueLaborCourt', errorMessage, function (value) {
    const { parent, path, createError } = this;

    if (!value) return true;

    if (parent.isReplacement) return true;

    return (
      Number(value) <= Number(parent.maxValueLaborCourt) + 0.01 ||
      createError({
        path,
        message:
          errorMessage ||
          `O limite máximo do recurso é de R$ ${numberToMoneyBr({
            value: Number(parent.maxValueLaborCourt),
            hideSymbol: true,
          })}`,
      })
    );
  });
});

Yup.addMethod(
  Yup.string,
  'validateDateWithDate',
  function (
    this,
    {
      secondDate,
      compareType,
      formatDate,
    }: {
      secondDate: Date;
      compareType: 'gt' | 'lt' | 'gte' | 'lte' | 'eq' | 'ne';
      formatDate?: 'date' | 'dateBr';
    },
    errorMessage
  ) {
    return this.test(
      'test-validateDateWithDate',
      errorMessage,
      function (value) {
        const { parent, path, createError } = this;

        if (!value) return true;

        const valueDate =
          formatDate === 'dateBr'
            ? dateBrToDate({ dateBrFormat: value })
            : dateStringOnlyToDate({ dateString: value });

        if (!valueDate) return true;

        return (
          compareDates({
            firstDate: valueDate,
            secondDate: secondDate,
            compareType,
          }) ||
          createError({
            path,
            message: errorMessage || 'Não permitido',
          })
        );
      }
    );
  }
);

Yup.addMethod(
  Yup.string,
  'validateDateWithField',
  function (
    this,
    {
      compareFieldName,
      compareType,
      formatDate,
    }: {
      compareFieldName: string;
      compareType: 'gt' | 'lt' | 'gte' | 'lte' | 'eq' | 'ne';
      formatDate?: 'date' | 'dateBr';
    },
    errorMessage
  ) {
    return this.test(
      'test-validateDateWithField',
      errorMessage,
      function (value) {
        const { parent, path, createError } = this;

        if (!value) return true;

        const valueDate =
          formatDate === 'dateBr'
            ? dateBrToDate({ dateBrFormat: value })
            : new Date(value);

        if (!valueDate) return true;

        const secondDate =
          formatDate === 'dateBr'
            ? dateBrToDate({ dateBrFormat: parent[compareFieldName] })
            : new Date(parent[compareFieldName]);

        if (!secondDate) return true;

        return (
          compareDates({
            firstDate: valueDate,
            secondDate: secondDate,
            compareType,
          }) ||
          createError({
            path,
            message: errorMessage || 'Não permitido',
          })
        );
      }
    );
  }
);

Yup.addMethod(Yup.string, 'strongPassword', function (errorMessage) {
  return this.test('test-strong-password', errorMessage, function (value) {
    const { path, createError } = this;

    const inNumberRegx = /\d/;
    const specialCharacterRegx = /[ !@#$%&*()_+\-=[\]{};':"\\|,.<>/?]/;

    if (!value) return true;

    switch (Boolean(value)) {
      // O campo deve ter pelo menos 8 caracteres.
      case value.length < 8:
        return createError({ path, message: errorMessage || ' ' });
      // O campo deve ter pelo menos um número.
      case !inNumberRegx.test(value):
        return createError({ path, message: errorMessage || ' ' });
      // O campo deve ter pelo menos um caractere especial.
      case !specialCharacterRegx.test(value):
        return createError({ path, message: errorMessage || ' ' });
      default:
        return true;
    }
  });
});
