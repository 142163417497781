import React, { createContext } from 'react';
import { LoadWhitelabelThemeByDomain } from '@/modules/whitelabel-theme/types/load-whitelabel-theme-by-domain-case';

type GlobalContextProps = {
  whitelabelTheme: LoadWhitelabelThemeByDomain.Result;
  history: string[];
};

export const GlobalContext = createContext({} as GlobalContextProps);

type Props = {
  children: React.ReactElement;
  whitelabelTheme: LoadWhitelabelThemeByDomain.Result;
  history: string[];
};

export const GlobalProvider = ({
  children,
  whitelabelTheme,
  history,
}: Props) => {
  return (
    <GlobalContext.Provider
      value={{
        whitelabelTheme,
        history,
      }}>
      {children}
    </GlobalContext.Provider>
  );
};
