import { AppProps } from 'next/app';
import Head from 'next/head';
import GlobalStyle from '@/common/styles/global';
import theme from '@/common/styles/theme';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import {
  Hydrate,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import { ThemeProvider } from 'styled-components';
import { useState } from 'react';
import {
  PAGE_AUTH_LOGIN,
  PAGE_BROKER_ACCESS_DENIED,
} from '@/common/consts/pages';
import { useRouter } from 'next/router';
import { AccessDeniedErrorName } from '@/common/consts/errors';
import '@/common/helpers/yup';
import { AuthProvider } from '@/common/contexts/auth-context';
import { Toaster } from 'react-hot-toast';
import { ModalProvider } from 'react-modal-hook';
import { TransitionGroup } from 'react-transition-group';
import { GlobalProvider } from '@/common/contexts/global-context';
import { registerLocale } from 'react-datepicker';
import ptBR from 'date-fns/locale/pt-BR';
import { LoadWhitelabelThemeByDomain } from '@/modules/whitelabel-theme/types/load-whitelabel-theme-by-domain-case';
import { Session } from 'next-auth';
import { ListBrokerCase } from '@/modules/broker/types/list-broker-case';
import { SessionProvider } from 'next-auth/react';
import { RefreshTokenHandler } from '@/common/components/refresh-token-handler';
import { useHistory } from '@/common/hooks/use-history';
import { httpLoadProfile } from '@/modules/auth/api/load-profile/http-load-profile';

type AppPropsWithLayout = AppProps & {
  pageProps: AppProps['pageProps'] & {
    dehydratedState: any;
    session: Session | null;
    domain: string;
    whitelabelTheme: LoadWhitelabelThemeByDomain.Model;
    intermediates: ListBrokerCase.Result;
  };
};

registerLocale('pt-BR', ptBR);

export default function App({ Component, pageProps }: AppPropsWithLayout) {
  const router = useRouter();
  const { history } = useHistory();
  const [refetchIntervalSeconds, setRefetchIntervalSeconds] = useState(0);
  const handleGlobalError = async (err: any) => {
    // Obtém o domínio atual dinamicamente
    const protocol = typeof window !== 'undefined' && window.location.protocol;
    const host = typeof window !== 'undefined' && window.location.host;
    const baseUrl = `${protocol}//${host}`;

    if (err.name === AccessDeniedErrorName) {
      try {
        await httpLoadProfile({});
        await router.replace(PAGE_BROKER_ACCESS_DENIED);
      } catch (err) {
        if (router.pathname !== PAGE_AUTH_LOGIN)
          router.replace(`${baseUrl}${PAGE_AUTH_LOGIN}`);
      }
    }
  };
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          mutations: {
            onError: handleGlobalError,
          },
          queries: {
            onError: handleGlobalError,
            retry: (failureCount: Number, error: Error) => {
              if (failureCount === 3) return false;
              return error?.name === AccessDeniedErrorName ? false : true;
            },
            refetchOnWindowFocus: false,
          },
        },
      })
  );

  const titlePageForTheme = () => {
    switch (pageProps?.whitelabelTheme?.theme?.name) {
      case 'original':
        return 'Original';
      case 'trr':
        return 'TRR';
      default:
        return 'Acoy';
    }
  };

  return (
    <SessionProvider
      session={pageProps.session}
      refetchInterval={refetchIntervalSeconds}>
      <QueryClientProvider client={queryClient}>
        <Hydrate state={pageProps.dehydratedState}>
          <ThemeProvider
            theme={(pageProps.whitelabelTheme?.theme as any) || theme}>
            <Head>
              <title>{titlePageForTheme()}</title>
              <link rel="shortcut icon" href="/img/favicon.png" />
              <meta
                name="viewport"
                content="width=device-width, initial-scale=1"
              />
              <link rel="manifest" href="/manifest.json" />
              <link rel="apple-touch-icon" href="/img/maskable_icon.png"></link>
              <meta name="theme-color" content="#009EF7" />
              <link rel="preconnect" href="https://fonts.googleapis.com" />
              <link
                rel="preconnect"
                href="https://fonts.gstatic.com"
                crossOrigin="true"
              />
              <meta
                name="description"
                content="Cotação, emissão e gerenciamento de apólices de seguro corporativo em minutos"
              />
              <script id={'smartsupp-script'} type={'text/javascript'}>
                {`
                var _smartsupp = _smartsupp || {};
                _smartsupp.key = 'e51769464ebf7eb36211dcca812590a88b1fa0de';
                window.smartsupp||(function(d) {
                  var s,c,o=smartsupp=function(){ o._.push(arguments)};o._=[];
                  s=d.getElementsByTagName('script')[0];c=d.createElement('script');
                  c.type='text/javascript';c.charset='utf-8';c.async=true;
                  c.src='https://www.smartsuppchat.com/loader.js?';s.parentNode.insertBefore(c,s);
                })(document);
              `}
              </script>
            </Head>
            <GlobalStyle />
            <Toaster
              toastOptions={{ style: { fontSize: '0.9em' } }}
              position={'top-right'}
            />
            <GlobalProvider
              whitelabelTheme={pageProps.whitelabelTheme}
              history={history}>
              <ModalProvider rootComponent={TransitionGroup}>
                <AuthProvider intermediates={pageProps.intermediates}>
                  <Component {...pageProps} />
                </AuthProvider>
              </ModalProvider>
            </GlobalProvider>
          </ThemeProvider>
        </Hydrate>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
      <RefreshTokenHandler
        setRefetchIntervalSeconds={(timeRemaining: number) => {
          setRefetchIntervalSeconds(timeRemaining);
        }}
      />
    </SessionProvider>
  );
}
