import Dinero from 'dinero.js';

Dinero.defaultCurrency = 'BRL';

type Money = string | number;

export const fixRounding = ({
  amount,
  precision = 0,
}: {
  amount: Money;
  precision?: number;
}) => {
  const power = Math.pow(10, precision);
  return Math.round(Number(amount) * power) / power;
};

const multiplyAndFixRounding100 = ({ amount }: { amount: Money }) => {
  return fixRounding({
    amount: Number(amount) * 100,
  });
};

export const sumMoney = ({
  amount,
  addValue,
}: {
  amount: Money;
  addValue: Money;
}) => {
  const amountDinero = Dinero({
    amount: multiplyAndFixRounding100({ amount }),
  });
  const addValueDinero = Dinero({
    amount: multiplyAndFixRounding100({ amount: addValue }),
  });
  return amountDinero.add(addValueDinero).toRoundedUnit(2);
};

export const subtractMoney = ({
  amount,
  subtractValue,
}: {
  amount: Money;
  subtractValue: Money;
}) => {
  const amountDinero = Dinero({
    amount: multiplyAndFixRounding100({ amount }),
  });
  const subtractValueDinero = Dinero({
    amount: multiplyAndFixRounding100({ amount: subtractValue }),
  });
  return amountDinero.subtract(subtractValueDinero).toRoundedUnit(2);
};

export const multiplyMoney = ({
  amount,
  multiplyValue,
}: {
  amount: Money;
  multiplyValue: Money;
}) => {
  const amountDinero = Dinero({
    amount: multiplyAndFixRounding100({ amount }),
  });
  return amountDinero.multiply(Number(multiplyValue)).toRoundedUnit(2);
};

export const divideMoney = ({
  amount,
  divideValue,
}: {
  amount: Money;
  divideValue: Money;
}) => {
  const amountDinero = Dinero({
    amount: multiplyAndFixRounding100({ amount }),
  });
  return amountDinero.divide(Number(divideValue)).toRoundedUnit(2);
};

export const percentageMoney = ({
  amount,
  percentageValue,
}: {
  amount: Money;
  percentageValue: Money;
}) => {
  const amountDinero = Dinero({
    amount: multiplyAndFixRounding100({ amount }),
  });
  return amountDinero.percentage(Number(percentageValue)).toRoundedUnit(2);
};

export const allocateMoney = ({
  amount,
  ratios,
}: {
  amount: Money;
  ratios: number[];
}) => {
  const amountDinero = Dinero({
    amount: multiplyAndFixRounding100({ amount }),
  });
  return amountDinero.allocate(ratios).map(money => money.toRoundedUnit(2));
};

export const percentageOf = ({
  amount,
  compareAmount,
}: {
  amount: Money;
  compareAmount: Money;
}) => {
  return ((Number(compareAmount) - Number(amount)) / Number(amount)) * 100;
};

export const numberToMoneyBr = ({
  value,
  hideSymbol,
}: {
  value: number;
  hideSymbol?: boolean;
}): string => {
  const formatter = new Intl.NumberFormat('pt-br', {
    style: 'currency',
    currency: 'BRL',
  });
  return hideSymbol
    ? formatter.format(value).replace('R$', '').trim()
    : formatter.format(value);
};

export const handleMoney = {
  sumMoney,
  subtractMoney,
  multiplyMoney,
  divideMoney,
  percentageMoney,
  allocateMoney,
  numberToMoneyBr,
  percentageOf,
};
