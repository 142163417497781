type CNJProps = {
  sequentialProcess: string;
  year: string;
  origin: string;
  jtr: string;
  verifyingDigit: string;
};

const useValidatorCnj = (value: string): boolean => {
  const cleanSpecialChar = (processNumber: string) =>
    processNumber.replace(/[^\w\s]/gi, '');

  const calculate = ({
    sequentialProcess,
    year,
    origin,
    jtr,
    verifyingDigit,
  }: CNJProps): string => {
    const rest = +sequentialProcess % 97;
    const valueCalculate = `${rest}${year}${jtr}`;

    const restTwo = +valueCalculate % 97;
    return `${restTwo}${origin}${verifyingDigit}`;
  };

  const validateDigitChecker = ({
    sequentialProcess,
    year,
    origin,
    jtr,
    verifyingDigit,
  }: CNJProps): boolean => {
    const valueChecker = calculate({
      sequentialProcess,
      year,
      origin,
      jtr,
      verifyingDigit,
    });
    return +valueChecker % 97 === 1;
  };

  if (!value) return false;

  const process = cleanSpecialChar(value);
  if (process.length < 20) return false;

  const sequentialProcess = process.substring(0, 7);
  const verifyingDigit = process.substring(7, 9);
  const year = process.substring(9, 13);
  const jtr = process.substring(13, 16);
  const origin = process.substring(16, 20);

  return validateDigitChecker({
    sequentialProcess,
    year,
    origin,
    jtr,
    verifyingDigit,
  });
};

export { useValidatorCnj };
